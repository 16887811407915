import { useLazyQuery } from "@apollo/client";
import { Card, Col, Row } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { GET_TODAYS_APPOINTMENT_COUNT } from "../../graphql/Queries";

const Dashboard = () => {
  const navigate = useNavigate();
  const [getAppointmentCount, { data: appointmentCount }] = useLazyQuery(
    GET_TODAYS_APPOINTMENT_COUNT
  );

  useEffect(() => {
    var endDate = new Date();
    getAppointmentCount({
      variables: {
        status: "booked",
        appointment_date: moment(endDate).format("YYYY-MM-DD"),
      },
    });
  }, [getAppointmentCount]);

  return (
    <Row justify="center">
      <Col span={6} onClick={() => navigate("/appointments")}>
        <Card
          className="text-center"
          title="Today's Booked Appoinments"
          bordered
        >
          <h1>{appointmentCount?.appointment_aggregate?.aggregate?.count}</h1>
        </Card>
      </Col>
    </Row>
  );
};

export default Dashboard;
