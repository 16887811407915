import React from "react";
import Appointments from "../pages/Appointments/Appointments";
import Configuration from "../pages/Configuration/Configuration";
import Dashboard from "../pages/Dashboard/Dashboard";
import Department from "../pages/Department/departmentList";
import Users from "../pages/User/Users";
import CompanyList from "../pages/Company/Company";
import AppointmentStatus from "../pages/MR/AppointmentStatus";
import BookAppointment from "../pages/MR/BookAppointment";
import AppointmentHistory from "../pages/MR/AppointmentHistory";
import Profile from "../pages/Profile/Profile";
import Availability from "../pages/Availability/Availability";

const routes = [
  { path: "/dashboard", name: "Dashboard", element: <Dashboard /> },
  { path: "/users", name: "Users", element: <Users /> },
  { path: "/appointments", name: "Appointments", element: <Appointments /> },
  { path: "/company", name: "Company", element: <CompanyList /> },
  { path: "/department", name: "Department", element: <Department /> },
  { path: "/configuration", name: "Configuration", element: <Configuration /> },
  { path: "/availability", name: "Availability", element: <Availability /> },
  {
    path: "/appointment-status",
    name: "AppointmentStatus",
    element: <AppointmentStatus />,
  },
  {
    path: "/appointment-history",
    name: "AppointmentHistory",
    element: <AppointmentHistory />,
  },
  {
    path: "/book-appointment",
    name: "AppointmentHistory",
    element: <BookAppointment />,
  },
  {
    path: "/my-profile",
    name: "UserProfile",
    element: <Profile />,
  },
];

export default routes;
