import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Card, Modal, Select } from "antd";
import "moment-timezone";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { INSERT_APPOINTMENT_ONE } from "../../graphql/Mutations";
import {
  ALLOWED_APPOINTMENTS_FOR_A_DAY,
  APPOINTMENT_COUNT_BY_DATE,
  CHECK_APPOINTMENT_EXIST_FOR_DATE,
  CHECK_LAST_APPOINTMENT_DATE_FOR_DEPARTMENT,
  GET_CONFIG,
  GET_USER_DETAILS_BY_ID,
  GET_DOCTOR_UNAVAILABILITY,
} from "../../graphql/Queries";
import "./mr.css";
import { getAvailableDates } from "../../Utils/dates/dates-availability";
import {
  anyAppointmentsInLast15Days,
  dailyAppointmentLimitCount,
  howManyAppointmentBookedForDate,
} from "./utils";
import {
  APPOINTMENT_STATUS,
  CONFIG_CONSTANTS,
} from "../../Constants/common-constants";

const { Option } = Select;

const BookAppointment = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const [userInfo, setUserInfo] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [availableDates, setAvailableDates] = useState([]);
  const [bookingDateInterval, setBookingDateInterval] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showBookingLoader, setShowBookingLoader] = useState(false);

  const { loading: userDataLoading, data: userData } = useQuery(
    GET_USER_DETAILS_BY_ID,
    {
      variables: {
        id: userId,
      },
    }
  );
  const { loading: configLoading, data: configData } = useQuery(GET_CONFIG);

  const {
    loading: docNotAvailLoading,
    data: docNotAvailDates,
    error: docNotAvailDatesError,
  } = useQuery(GET_DOCTOR_UNAVAILABILITY);

  const getConfigValueByName = useCallback(
    (name) => {
      return configData.configuration.find((x) => x.name === name)?.value;
    },
    [configData]
  );

  useEffect(() => {
    if (!configLoading && configData) {
      setBookingDateInterval(
        getConfigValueByName(CONFIG_CONSTANTS.GAP_BETWEEN_APPOINTMENTS)
      );
    }
  }, [configData, configLoading, getConfigValueByName]);

  useEffect(() => {
    const shouldFetchAvailableDates =
      !configLoading && configData && !docNotAvailLoading && docNotAvailDates;
    if (shouldFetchAvailableDates) {
      const lastBookingTimeForCurrentDay = getConfigValueByName(
        CONFIG_CONSTANTS.LAST_BOOKING_TIME_FOR_CURRENT_DAY
      );
      const availableDateResponse = getAvailableDates(
        docNotAvailDates,
        lastBookingTimeForCurrentDay
      );
      setAvailableDates(availableDateResponse);
    }
  }, [
    docNotAvailDates,
    docNotAvailLoading,
    configLoading,
    configData,
    getConfigValueByName,
  ]);

  useEffect(() => {
    if (!userDataLoading && userData) {
      setUserInfo(userData.user_by_pk);
    }
  }, [userData, userDataLoading]);

  const [getLastAppointmentDate] = useLazyQuery(
    CHECK_LAST_APPOINTMENT_DATE_FOR_DEPARTMENT
  );

  const [checkAppointmentForDate] = useLazyQuery(
    CHECK_APPOINTMENT_EXIST_FOR_DATE
  );

  const [appointmentCountByDate] = useLazyQuery(APPOINTMENT_COUNT_BY_DATE);

  const [getAllowedAppointmentForADayFromConfig] = useLazyQuery(
    ALLOWED_APPOINTMENTS_FOR_A_DAY
  );

  const [createAppointmentInServer] = useMutation(INSERT_APPOINTMENT_ONE);

  const bookAppointmentInServer = async (_user, _appointmentDate) => {
    const companyId = _user.company.id;
    const departmentId = _user.departmentByDepartmentId.id;
    const isUserDeleted = _user.is_deleted;
    const isUserBlocked = _user.is_blocked;
    const isUserActive = !isUserDeleted && !isUserBlocked;

    if (isUserActive) {
      // Check user has already booked a new appointment for _appointmentDate ?
      const checkAppointmentForDateResponse = await checkAppointmentForDate({
        variables: {
          appointment_date: _appointmentDate,
          status: APPOINTMENT_STATUS.BOOKED,
          user_id: _user.id,
        },
      });

      if (checkAppointmentForDateResponse?.data?.appointment?.length > 0) {
        setModalTitle("Info");
        setModalMessage(
          "You have already booked an appointment for this date."
        );
        setSuccessModal(true);
        return;
      } else {
        // Check that company and department user belongs to has already been confirmed with the appointment date in last 15 days ?
        const hasAnyoneInDeptMeDocInLast15Days =
          await anyAppointmentsInLast15Days(
            _appointmentDate,
            getLastAppointmentDate,
            companyId,
            departmentId,
            bookingDateInterval
          );

        if (hasAnyoneInDeptMeDocInLast15Days) {
          setModalTitle("Sorry, Your Appointment can't be Booked!");
          setModalMessage(
            `Someone from your department has already booked an appointment in last ${bookingDateInterval} days.`
          );
          setSuccessModal(true);
          return;
        } else {
          // check for the number of appointment allowed for that particular date.
          const appointmentCount = await howManyAppointmentBookedForDate(
            appointmentCountByDate,
            _appointmentDate
          );

          const dailyAppointmentLimit = await dailyAppointmentLimitCount(
            getAllowedAppointmentForADayFromConfig,
            selectedDate
          );

          if (appointmentCount < dailyAppointmentLimit) {
            const createAppointmentResponse = await createAppointmentInServer({
              variables: {
                appointment_date: _appointmentDate,
                user_id: userId,
                status: APPOINTMENT_STATUS.BOOKED,
              },
            });
            if (!createAppointmentResponse.errors) {
              setSuccessModal(true);
              setModalTitle("Success");
              setModalMessage("Appointment booked successfully.");
            }
          } else {
            setModalTitle("Info");
            setModalMessage("Slots are full for this date.");
            setSuccessModal(true);
            return;
          }
        }
      }
    }
  };

  const handleBookNowAppointmentButtonClick = async () => {
    setShowBookingLoader(true);
    const availSelectedDate = availableDates.find(
      (d) => d.dateInFormat === selectedDate
    )?.date;
    try {
      await bookAppointmentInServer(userInfo, availSelectedDate);
    } catch (err) {
      setModalTitle("Error");
      setModalMessage("Something went wrong!, Please contact support");
      setSuccessModal(true);
      setShowBookingLoader(false);
    }
  };

  const onOkClick = () => {
    setSuccessModal(false);
    setModalMessage("");
    setModalTitle("");
    navigate("/appointment-status");
  };

  return (
    <div className="container px-0">
      <Modal
        open={successModal}
        closable={false}
        footer={false}
        title={<div className="text-center">{modalTitle}</div>}
      >
        <div className="my-3 text-center">{modalMessage}</div>
        <Button
          className="text-center"
          type="primary"
          shape="round"
          onClick={onOkClick}
        >
          OK
        </Button>
      </Modal>
      <Card className="mainCard">
        {availableDates && (
          <div>
            <h4>Select Appointment Date</h4>
            <div className="d-flex">
              <Select
                placeholder="Select Appointment Date"
                className="date-dropdown appointmentDateSelectBox"
                loading={availableDates.length === 0}
                onChange={(value) => {
                  setSelectedDate(value);
                }}
              >
                {availableDates.map((date) => (
                  <Option key={date.date} value={date.dateInFormat}>
                    {date.dateInFormat}
                  </Option>
                ))}
              </Select>
              <Button
                type="primary"
                onClick={handleBookNowAppointmentButtonClick}
                disabled={!selectedDate}
                loading={showBookingLoader}
              >
                Book Now
              </Button>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default BookAppointment;
