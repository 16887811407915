import React from "react";
import { Menu, Button } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function DrawerContent({
  navMenuItems,
  handleLogoutClick,
  closeMenuDrawer,
  userName,
}) {
  const navigate = useNavigate();

  return (
    <div>
      <div
        className="menuProfile"
        onClick={() => {
          closeMenuDrawer();
          navigate("/my-profile");
        }}
      >
        <span>{userName ? `Hello, ${userName}` : null}</span>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        items={navMenuItems}
        defaultSelectedKeys={[`${window.location.pathname.split("/")[1]}`]}
      />
      <Button
        className="logout-button"
        type="primary"
        shape="round"
        icon={<PoweroffOutlined />}
        onClick={handleLogoutClick}
      >
        Logout
      </Button>
    </div>
  );
}

export default DrawerContent;
