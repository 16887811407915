import { useLazyQuery } from "@apollo/client";
import { Button, Card, Typography } from "antd";
import Meta from "antd/lib/card/Meta";
import * as moment from "moment";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import FullLoader from "../../components/Loader/FullLoader";
import { GET_APPOINTMENT_HISTORY } from "../../graphql/Queries";

function AppointmentHistory() {
  const user_id = localStorage.getItem("user_id");

  const navigate = useNavigate();
  const [
    getAppointmentHistory,
    {
      data: appointmentHisData,
      error: appointmentHisError,
      loading: appointmentHisLoading,
    },
  ] = useLazyQuery(GET_APPOINTMENT_HISTORY);

  useEffect(() => {
    getAppointmentHistory({
      variables: {
        user_id,
      },
    });
  }, [getAppointmentHistory, user_id]);

  if (appointmentHisLoading) {
    return <FullLoader />;
  }

  return (
    <div className="container">
      <Card className="mainCard">
        <div className="d-flex flex-wrap flex-sm-row flex-column mb-sm-0 mb-3">
          <Typography.Title level={3}>Appointment History</Typography.Title>
          <div className="ms-auto"></div>
        </div>

        {appointmentHisData?.appointment?.length > 0 ? (
          appointmentHisData?.appointment?.map((apt) => {
            return (
              <>
                <Card
                  key={apt.id}
                  style={{
                    width: 300,
                    margin: "10px 0px",
                  }}
                  bordered={true}
                  bodyStyle={{
                    color: "teal",
                    borderWidth: "1px",
                    borderStyle: "solid",
                  }}
                >
                  <Meta
                    key={apt.id}
                    title={moment(apt.appointment_date).format("DD-MMM-YYYY")}
                    description={apt.status?.toUpperCase()}
                  />
                </Card>
              </>
            );
          })
        ) : (
          <>
            <Typography.Text level={3}>
              You haven't any booked any appointment so far, You can book your
              first appointment.
            </Typography.Text>
            <br />
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                key="book"
                type="primary"
                onClick={() => navigate("/book-appointment")}
              >
                Book now
              </Button>
            </div>
          </>
        )}
      </Card>
    </div>
  );
}

export default AppointmentHistory;
