
import moment from "moment";
import { APPOINTMENT_STATUS } from "../../Constants/common-constants";
import { WEEK_DAYS } from "../../Constants/dates-constants";


export const anyAppointmentsInLast15Days = async (
  appointmentDate,
  getLastAppointmentDate,
  companyId,
  departmentId,
  bookingDateInterval
) => {
  // To Test the working flow pass the custom date in the below variable
  // appointmentDate = "2023-01-20";
  const _appointmentDate = new Date(appointmentDate);
  _appointmentDate.setDate(_appointmentDate.getDate() - bookingDateInterval);
  const appointmentDateInLast15DaysStr = moment(_appointmentDate).format("YYYY-MM-DD");
  const getLastAppointmentDateResponse = await getLastAppointmentDate({
    variables: {
      company_id: companyId,
      department_id: departmentId,
      appointment_date: appointmentDate,
      last_booked_range_date: appointmentDateInLast15DaysStr,
      status: [APPOINTMENT_STATUS.BOOKED, APPOINTMENT_STATUS.ATTENDED],
    },
  });
  const hasAnyoneInDeptMeDocInLast15Days =
    getLastAppointmentDateResponse?.data?.appointment?.length > 0;
  return hasAnyoneInDeptMeDocInLast15Days;
};



export const dailyAppointmentLimitCount = async (
  getAllowedAppointmentForADayFromConfig,
  selectedDate
) => {
  const allowedAppointmentsForADay =
    await getAllowedAppointmentForADayFromConfig({
      variables: {
        config_name: `%${WEEK_DAYS[new Date(selectedDate).getDay()]}%`,
      },
    });
  const dailyAppointmentLimit =
    allowedAppointmentsForADay.data?.configuration[0]?.value;
  return dailyAppointmentLimit;
};

export const howManyAppointmentBookedForDate = async (
  appointmentCountByDate,
  _appointmentDate
) => {
  const appointmentCountByDateResponse = await appointmentCountByDate({
    variables: {
      appointment_date: _appointmentDate,
      status: APPOINTMENT_STATUS.BOOKED,
    },
  });

  const appointmentCount =
    appointmentCountByDateResponse.data?.appointment_aggregate?.aggregate
      ?.count;
  return appointmentCount;
};
