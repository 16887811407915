export const localStorageUtils = {

    setItem: (key, data) => {
        localStorage.setItem(key, data);
    },

    getItem: (key) => localStorage.getItem(key),

    removeItem(key) {
        localStorage.removeItem(key);
    }
}