import { useQuery } from "@apollo/client";
import { Button, Form, Input, Modal } from "antd";
import React, { useEffect } from "react";
import { GET_DEPT_DETAILS_BY_ID } from "../../graphql/Queries";

const EditDepartment = ({
  visible,
  onCancel,
  onSubmit,
  onLoading,
  departmentId,
}) => {
  const [form] = Form.useForm();

  const { loading: deptDataLoading, data: deptData } = useQuery(
    GET_DEPT_DETAILS_BY_ID,
    {
      variables: {
        id: departmentId,
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (deptData && !deptDataLoading) {
      form.setFieldsValue({
        name: deptData?.department_by_pk?.name,
      });
    }
  }, [form, deptData, deptDataLoading]);

  return (
    <Modal
      title="UPDATE DEPARTMENT"
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        name="department_form"
        form={form}
        className="department-form"
        onFinish={onSubmit}
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter department name!",
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="Department Name" />
        </Form.Item>
        <Form.Item className="text-center">
          <Button
            type="default"
            shape="round"
            onClick={onCancel}
            className="register-form-back-button"
          >
            Cancel
          </Button>
          <Button
            loading={onLoading}
            type="primary"
            shape="round"
            htmlType="submit"
            className="register-form-button"
          >
            {onLoading ? "Updating..." : "Update"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default EditDepartment;
