import * as moment from "moment";
import "moment-timezone";
import {
  TIMEZONE,
  YEAR_MONTH_DATE_FORMAT_WITH_DASHES,
} from "../../Constants/dates-constants";

function getFormattedDate(date) {
  return {
    date: date.format(YEAR_MONTH_DATE_FORMAT_WITH_DASHES),
    time: date.format("HH:mm:ss"),
    dateInFormat: date.format("DD MMMM YYYY"),
    dateInISOFormat: date.toISOString(),
  };
}

export const isSunday = (date) => date.day() === 0;

const flattenedUnavailableDates = (docNotAvailDates) => {
  const dateArray = docNotAvailDates.map((x) => {
    if (x.from_date === x.to_date) {
      return [x.from_date];
    } else {
      const from = moment(x.from_date);
      const to = moment(x.to_date);
      const dates = [];
      while (from.isBefore(to)) {
        dates.push(from.format(YEAR_MONTH_DATE_FORMAT_WITH_DASHES));
        from.add(1, "days");
      }
      dates.push(to.format(YEAR_MONTH_DATE_FORMAT_WITH_DASHES));
      return dates;
    }
  });
  const flattenedDates = dateArray.flat();
  return flattenedDates;
};

const isDoctorAvailable = (datesArray, date) => !datesArray.includes(date);

export const getAvailableDates = (
  docNotAvailDates,
  lastBookingTimeForCurrentDay
) => {
  const availableDays = [];

  try {
    const datesDocIsNotAvailable = flattenedUnavailableDates(
      docNotAvailDates?.doctor_unavailability
    );

    for (let i = 0; availableDays.length < 3; i++) {
      const nextDay = moment.tz(TIMEZONE).add(i, "days");
      const isAppointmentTimeElapsedForToday =
        i === 0 && nextDay.hours() > lastBookingTimeForCurrentDay;

      const isDateValidForAppointment =
        !isAppointmentTimeElapsedForToday &&
        !isSunday(nextDay) &&
        isDoctorAvailable(
          datesDocIsNotAvailable,
          nextDay.format(YEAR_MONTH_DATE_FORMAT_WITH_DASHES)
        );

      if (isDateValidForAppointment) {
        availableDays.push(getFormattedDate(nextDay));
      }
    }
  } catch (err) {
    console.log("Error in getAvailableDates", err);
    throw err;
  }
  return availableDays;
};
