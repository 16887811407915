import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation login($password: String!, $username: String!) {
    login(username: $username, password: $password) {
      access_token
      id
      role
      org_id
    }
  }
`;

export const REGISTER_USER = gql`
  mutation register(
    $name: String!
    $phone: String!
    $email: String!
    $role: String!
    $department_id: String
    $company_id: String
    $org_id: String!
  ) {
    register(
      user: {
        department_id: $department_id
        email: $email
        phone: $phone
        role: $role
        name: $name
        company_id: $company_id
        org_id: $org_id
      }
    ) {
      message
      status
    }
  }
`;

export const DELETE_USER = gql`
  mutation update_user_by_pk($id: uuid!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: { is_deleted: true }) {
      id
    }
  }
`;

export const BLOCK_USER = gql`
  mutation update_user_by_pk($id: uuid!, $is_blocked: Boolean) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: { is_blocked: $is_blocked }
    ) {
      id
    }
  }
`;

export const INSERT_COMPANY_ONE = gql`
  mutation insert_company_one($address: String, $name: String, $type: String) {
    insert_company_one(
      object: { address: $address, name: $name, type: $type }
    ) {
      id
    }
  }
`;

export const UPDATE_PASSWORD_ON_REG = gql`
  mutation updateUserPasswordRegistration(
    $password: String!
    $username: String!
  ) {
    updateUserPasswordRegistration(
      user: { password: $password, username: $username }
    ) {
      message
      status
    }
  }
`;

export const UPDATE_COMPANY_ONE = gql`
  mutation update_company_by_pk(
    $id: uuid = ""
    $address: String = ""
    $name: String = ""
    $type: String = ""
  ) {
    update_company_by_pk(
      pk_columns: { id: $id }
      _set: { address: $address, name: $name, type: $type }
    ) {
      address
    }
  }
`;

export const UPDATE_CONFIG_ONE = gql`
  mutation update_configuration_by_pk(
    $id: uuid = ""
    $value: String = ""
    $name: String = ""
  ) {
    update_configuration_by_pk(
      pk_columns: { id: $id }
      _set: { value: $value, name: $name }
    ) {
      id
    }
  }
`;

export const UPDATE_DEPARTMENT_ONE = gql`
  mutation update_department_by_pk($id: uuid = "", $name: String = "") {
    update_department_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      id
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation change_password($new_password: String!) {
    change_password(new_password: $new_password) {
      message
      status
    }
  }
`;

export const INSERT_DEPARTMENT_ONE = gql`
  mutation insert_department_one($name: String) {
    insert_department_one(object: { name: $name }) {
      created_at
      id
      name
      updated_at
    }
  }
`;

export const INSERT_CONFIG_ONE = gql`
  mutation insert_configuration_one($name: String, $value: String) {
    insert_configuration_one(object: { name: $name, value: $value }) {
      id
    }
  }
`;

export const DELETE_FOLLOWER_ONE = gql`
  mutation delete_followers($followed_by: uuid, $follow_to: uuid) {
    delete_followers(
      where: {
        followed_by: { _eq: $followed_by }
        follow_to: { _eq: $follow_to }
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_MULTIPLE_APPOINTMENTS = gql`
  mutation delete_multiple_appointments($ids: [uuid!] = "") {
    update_appointment_many(
      updates: { _set: { status: "delete" }, where: { id: { _in: $ids } } }
    ) {
      affected_rows
    }
  }
`;

export const ATTEND_MULTIPLE_APPOINTMENTS = gql`
  mutation update_appointment_by_pk($id: uuid!, $status: String) {
    update_appointment_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      id
      status
    }
  }
`;

export const CREATE_APPOINTMENT = gql`
  mutation createAppointment($appointmentDate: String = "") {
    createAppointment(appointmentDate: $appointmentDate) {
      id
    }
  }
`;

export const UPDATE_USER_BY_PK = gql`
  mutation UPDATE_USER_BY_PK(
    $id: uuid!
    $company_id: uuid
    $department_id: uuid
    $name: String!
    $phone: String!
  ) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        company_id: $company_id
        department_id: $department_id
        name: $name
        phone: $phone
      }
    ) {
      id
    }
  }
`;

export const INSERT_APPOINTMENT_ONE = gql`
  mutation insert_appointment_one(
    $appointment_date: date
    $user_id: uuid
    $status: String
  ) {
    insert_appointment_one(
      object: {
        appointment_date: $appointment_date
        user_id: $user_id
        status: $status
      }
    ) {
      id
      status
    }
  }
`;

export const INSERT_DOCTOR_UNAVAILABLE_ONE = gql`
  mutation INSERT_DOCTOR_UNAVAILABLE_ONE(
    $from_date: date = ""
    $to_date: date = ""
  ) {
    insert_doctor_unavailability_one(
      object: { from_date: $from_date, to_date: $to_date }
    ) {
      id
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation login($password: String!, $username: String!) {
    login(username: $username, password: $password) {
      access_token
      id
      role
    }
  }
`;

//CANCEL APPOINTMENT - set status to cancel
export const CANCEL_APPOINTMENT = gql`
  mutation cancel_appointment_by_pk($id: uuid!, $status: String) {
    update_appointment_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      id
      status
    }
  }
`;
