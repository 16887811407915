import { UserAddOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Empty, message, Space, Spin, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Loader from "../../components/Loader/Loader";
import {
  INSERT_DEPARTMENT_ONE,
  UPDATE_DEPARTMENT_ONE,
} from "../../graphql/Mutations";
import { GET_DEPARTMENT_DETAILS } from "../../graphql/Queries";
import AddDepartment from "./addDepartment";
import EditDepartment from "./EditDepartment";

const { Column } = Table;

const Department = () => {
  const [departmentDetails, setDepartmentDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [departmentId, setDepartmentId] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);

  const {
    loading: deptDataLoading,
    data: deptData,
    error: deptDataError,
    refetch,
  } = useQuery(GET_DEPARTMENT_DETAILS);

  const [registerDepartment, { loading: registering, error }] = useMutation(
    INSERT_DEPARTMENT_ONE
  );

  const [updateDepartment, { loading: updating, error: updateError }] =
    useMutation(UPDATE_DEPARTMENT_ONE);

  useEffect(() => {
    if (!deptDataLoading && deptData) {
      setDepartmentDetails(deptData?.department);
    }
  }, [deptData, deptDataLoading]);

  if (deptDataError || error || updateError) {
    console.log(
      "deptDataError || error || updateError",
      deptDataError,
      error,
      updateError
    );
  }

  const submitHandler = async (user) => {
    setIsModalOpen(false);
    try {
      const response = await registerDepartment({
        variables: {
          name: user.name.trim(),
        },
      });
      if (!response.error) {
        message.success("Department added successfully");
        refetch();
      } else {
        message.error("Invalid Department data, Please contact support");
      }
    } catch (err) {
      message.error("Invalid Department data, Please contact support");
    }
  };
  const onUpdate = async (department) => {
    setEditModalOpen(false);
    try {
      const response = await updateDepartment({
        variables: {
          id: departmentId,
          name: department.name.trim(),
        },
      });
      if (!response.error) {
        message.success("Configuration updated successfully");
        refetch();
      } else {
        message.error("Invalid Configuration data, Please contact support");
      }
    } catch (err) {
      message.error("Invalid Configuration data, Please contact support");
    }
    setDepartmentId("");
  };

  const handleEdit = (record) => {
    setDepartmentId(record.id);
    setEditModalOpen(true);
  };

  return (
    <>
      <AddDepartment
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onSubmit={submitHandler}
        onLoading={registering}
      />

      <EditDepartment
        visible={editModalOpen}
        onCancel={() => setEditModalOpen(false)}
        onSubmit={onUpdate}
        onLoading={updating}
        departmentId={departmentId}
      />

      <div className="animated fadeIn">
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <Button
                  className="register-button d-inline-flex align-items-center"
                  type="primary"
                  size="large"
                  shape="round"
                  icon={<UserAddOutlined />}
                  onClick={() => setIsModalOpen(true)}
                >
                  Add Department
                </Button>
              </CardHeader>
              <CardBody>
                {deptDataLoading ? (
                  <Loader />
                ) : departmentDetails?.length > 0 ? (
                  <Table
                    bordered={true}
                    loading={deptDataLoading}
                    dataSource={departmentDetails}
                    rowKey={(record) => record.id}
                    pagination={false}
                  >
                    <Column title="Name" key="name" dataIndex="name" />

                    <Column
                      title="Created Date"
                      key="created_date"
                      render={(record) =>
                        moment(record.created_at).format("Do MMMM YYYY")
                      }
                    />
                    <Column
                      title="Updated Date"
                      key="updated_date"
                      render={(record) =>
                        moment(record.created_at).format("Do MMMM YYYY")
                      }
                    />
                    <Column
                      title="Action"
                      key="action"
                      render={(record) => (
                        <Space>
                          <Button
                            shape="round"
                            type="primary"
                            onClick={() => {
                              handleEdit(record);
                            }}
                          >
                            Edit
                          </Button>
                          <Space />
                          <Button
                            shape="round"
                            type="primary"
                            danger
                            ghost
                            onClick={() => {}}
                          >
                            Delete
                          </Button>
                        </Space>
                      )}
                    />
                  </Table>
                ) : (
                  <Empty
                    description={<span>No Company Available</span>}
                  ></Empty>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Department;
