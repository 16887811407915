import { Button, Typography, Card, Modal, message } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_APPOINTMENT_STATUS_BY_USER_ID,
  GET_CONFIG,
} from "../../graphql/Queries";
import { CANCEL_APPOINTMENT } from "../../graphql/Mutations";
import * as moment from "moment";
import "moment-timezone";
import { TIMEZONE } from "../../Constants/dates-constants";
import {
  APPOINTMENT_STATUS,
  CONFIG_CONSTANTS,
} from "../../Constants/common-constants";
import FullLoader from "../../components/Loader/FullLoader";

import "./mr.css";

function AppointmentStatus() {
  const user_id = localStorage.getItem("user_id");
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(true);
  const [upcomingAppointmentDate, setUpcomingAppointmentDate] = useState({});
  const navigate = useNavigate();

  const [cancelAppointmentMutation, { loading: cancellingApt }] =
    useMutation(CANCEL_APPOINTMENT);

  const { data: configData } = useQuery(GET_CONFIG);

  const [
    getAppointmentStatus,
    {
      data: appointmentStatusData,
      error: appointmentStatusError,
      loading: appointmentStatusLoading,
    },
  ] = useLazyQuery(GET_APPOINTMENT_STATUS_BY_USER_ID);

  const isShowCancelAppointmentButton = useCallback(
    (lastTimeToCancel) => {
      const isAppointmentDateToday = moment(
        upcomingAppointmentDate.appointment_date
      ).isSame(moment.tz(TIMEZONE).format("YYYY-MM-DD"));

      if (!isAppointmentDateToday) return true;

      const currentTime = moment.tz(TIMEZONE).format("HH");
      return currentTime < lastTimeToCancel;
    },
    [upcomingAppointmentDate]
  );

  const lastTimeToCancel = configData?.configuration?.find(
    (config) => config.name === CONFIG_CONSTANTS.ALLOWED_MEETING_CANCELLATION
  )?.value;

  useEffect(() => {
    if (
      upcomingAppointmentDate?.appointment_date &&
      configData?.configuration
    ) {
      const shouldShowCancelAppointmentBtn =
        isShowCancelAppointmentButton(lastTimeToCancel);
      setShowCancelButton(shouldShowCancelAppointmentBtn);
    }
  }, [
    upcomingAppointmentDate,
    configData,
    showCancelButton,
    setShowCancelButton,
    isShowCancelAppointmentButton,
    lastTimeToCancel,
  ]);

  useEffect(() => {
    getAppointmentStatus({
      variables: {
        user_id,
        status: APPOINTMENT_STATUS.BOOKED,
        appointment_date: moment.tz(TIMEZONE).format("YYYY-MM-DD"),
      },
    });
  }, [getAppointmentStatus, user_id]);

  useEffect(() => {
    if (
      !appointmentStatusError &&
      appointmentStatusData?.appointment.length > 0
    ) {
      setUpcomingAppointmentDate(appointmentStatusData?.appointment[0]);
    }
  }, [appointmentStatusData, appointmentStatusError]);

  const showCancelAppointmentConfirmationModal = () => setOpenCancelModal(true);

  const handleYesCancel = async () => {
    setOpenCancelModal(false);
    if (!isShowCancelAppointmentButton(lastTimeToCancel)) {
      setShowCancelButton(false);
      message.error(
        `You can only cancel your appointment before ${moment(
          lastTimeToCancel,
          "HH"
        ).format("h A")}.`
      );
      return;
    }
    const response = await cancelAppointmentMutation({
      variables: {
        id: upcomingAppointmentDate?.id,
        status: APPOINTMENT_STATUS.CANCELLED,
      },
    });

    if (!response.error) {
      setUpcomingAppointmentDate(response);
      message.success("Appointments cancelled successfully");
    } else {
      message.error("Something went wrong, please contact support");
    }
  };

  const handleNoCancel = () => setOpenCancelModal(false);

  const getDisplayDate = (date) =>
    moment(date).tz(TIMEZONE).format("DD MMMM YYYY");

  const appointmentTitleMessage = () =>
    `You have an appointment on ${getDisplayDate(
      upcomingAppointmentDate?.appointment_date
    )}`;

  const getCancelModalMessage = () =>
    `Are you sure you want to cancel your appointment on ${getDisplayDate(
      upcomingAppointmentDate?.appointment_date
    )} ?`;

  if (appointmentStatusLoading || cancellingApt) {
    return <FullLoader />;
  }

  return (
    <div className="container px-0">
      <Card className="mainCard">
        <div className="d-flex flex-wrap flex-sm-row flex-column mb-sm-0 mb-4 text-center">
          <Typography.Title level={3} className="mb-sm-0 mb-3">
            Appointment Status
          </Typography.Title>
        </div>
        {upcomingAppointmentDate?.status === APPOINTMENT_STATUS.BOOKED ? (
          <>
            <Typography.Title level={3} style={{ margin: 0, color: "teal" }}>
              {appointmentTitleMessage()}
            </Typography.Title>
            <br />
            <Typography.Title level={5}>
              Please be present at the Raj Clinic before time.
            </Typography.Title>
            <br />
            <div className="btnCancelAppointment">
              {showCancelButton && (
                <Button
                  shape="round"
                  type="primary"
                  danger
                  onClick={showCancelAppointmentConfirmationModal}
                >
                  Cancel Appointment
                </Button>
              )}
            </div>
          </>
        ) : (
          <div className="text-center my-3">
            <Typography.Title level={4}>
              You do not have any upcoming appointments scheduled.
            </Typography.Title>
            <div className="text-center" style={{ marginTop: 30 }}>
              <Button
                shape="round"
                type="primary"
                onClick={() => navigate("/book-appointment")}
              >
                Book Appointment
              </Button>
            </div>
          </div>
        )}
      </Card>
      <Modal
        open={openCancelModal}
        closable={false}
        centered
        title="Cancel Appointment"
        footer={[
          <Button
            key="yes"
            type="primary"
            shape="round"
            danger
            onClick={handleYesCancel}
          >
            YES
          </Button>,
          <Button
            key="no"
            type="primary"
            shape="round"
            onClick={handleNoCancel}
          >
            NO
          </Button>,
        ]}
      >
        <div className="my-3 text-center">{getCancelModalMessage()}</div>
      </Modal>
    </div>
  );
}

export default AppointmentStatus;
