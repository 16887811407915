import { useQuery, useMutation } from "@apollo/client";
import { Button, Form, Input, InputNumber, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { UPDATE_USER_BY_PK } from "../../graphql/Mutations";
import {
  GET_COMPANY_DETAILS,
  GET_DEPARTMENT_DETAILS,
  GET_USER_DETAILS_BY_ID,
} from "../../graphql/Queries";

const EditUser = ({ userId, onCancel, onLoading, refetch }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [visible, setVisible] = useState(false);

  const {
    loading: compDataLoading,
    data: compData,
    error: compDataError,
  } = useQuery(GET_COMPANY_DETAILS);

  const {
    loading: deptDataLoading,
    data: deptData,
    error: deptDataError,
  } = useQuery(GET_DEPARTMENT_DETAILS);

  const [updateUser, { loading: savingUserDetails }] =
    useMutation(UPDATE_USER_BY_PK);

  const { loading: userDataLoading, data: userData } = useQuery(
    GET_USER_DETAILS_BY_ID,
    {
      variables: {
        id: userId,
      },
    }
  );

  if (compDataError || deptDataError) {
    console.log("compDataError", compDataError);
    console.log("deptDataError", deptDataError);
  }

  const handleSaveUserForm = async (user) => {
    try {
      const response = await updateUser({
        variables: {
          id: userId,
          name: user.name.trim(),
          phone: `${user.phone}`,
          department_id: user.department,
          company_id: user.company,
        },
      });
      if (response.data.update_user_by_pk.id) {
        message.success("User details updated successfully");
        setVisible(false);
        refetch();
        onCancel();
      } else {
        message.error("Invalid User data");
      }
    } catch (err) {
      message.error("Invalid User data");
    }
  };

  useEffect(() => {
    if (userData) {
      const { name, phone, email, company, departmentByDepartmentId } =
        userData.user_by_pk;
      form.setFieldsValue({
        name,
        phone,
        email,
        company: company?.id,
        department: departmentByDepartmentId?.id,
      });
      setVisible(true);
    }
  }, [form, userData, userDataLoading]);

  return (
    <Modal title="Edit User" open={visible} onCancel={onCancel} footer={false}>
      <Form
        name="edit_user"
        form={form}
        className="login-form"
        onFinish={handleSaveUserForm}
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter Full-Name!",
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="Full-Name" />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Please enter your Phone Number!",
            },
          ]}
          className="mb-3"
        >
          <InputNumber
            className="w-100"
            controls={false}
            placeholder="Phone Number"
            maxLength={10}
            minLength={10}
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your Email!",
            },
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="Email" />
        </Form.Item>
        {/* <Form.Item
          className="mb-3"
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your Password!",
            },
          ]}
        >
          <Input type="password" placeholder="Password" />
        </Form.Item> */}
        <Form.Item name="department" className="mb-3">
          <Select
            allowClear
            loading={deptDataLoading}
            placeholder="Select Department"
            showSearch
            placement="bottomLeft"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {deptData && deptData?.department?.length > 0
              ? deptData?.department?.map((dept) => {
                  return <Option value={dept.id}>{dept?.name}</Option>;
                })
              : "No Data"}
          </Select>
        </Form.Item>
        <Form.Item name="company" className="mb-3">
          <Select
            allowClear
            loading={compDataLoading}
            placeholder="Select company"
            showSearch
            placement="bottomLeft"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {compData && compData.company.length > 0
              ? compData?.company?.map((comp) => {
                  return <Option value={comp.id}>{comp.name}</Option>;
                })
              : "No Data"}
          </Select>
        </Form.Item>
        <Form.Item className="text-center">
          <Button
            type="default"
            shape="round"
            onClick={onCancel}
            className="register-form-back-button"
          >
            Cancel
          </Button>
          <Button
            loading={onLoading}
            type="primary"
            shape="round"
            htmlType="submit"
            className="register-form-button"
          >
            {savingUserDetails ? "Loading..." : "Save"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default EditUser;
