import { gql } from "@apollo/client";

export const GET_USER_DETAILS = gql`
  query user {
    user(where: { is_deleted: { _eq: false }, role: { _eq: "user" } }) {
      company {
        id
        name
      }
      departmentByDepartmentId {
        id
        name
      }
      email
      id
      name
      phone
      created_at
      is_deleted
      is_blocked
    }
  }
`;

export const GET_ALL_APPOINEMENTS = gql`
  query appointment(
    $status: String = "delete"
    $name: String = "%%"
    $appointmentStartDate: date
    $appointmentEndDate: date
  ) {
    appointment(
      where: {
        status: { _neq: $status }
        user: { name: { _ilike: $name } }
        appointment_date: {
          _gte: $appointmentStartDate
          _lte: $appointmentEndDate
        }
      }
      order_by: { appointment_date: desc }
    ) {
      appointment_date
      created_at
      id
      status
      updated_at
      user {
        company {
          name
          id
        }
        departmentByDepartmentId {
          name
          id
        }
        id
        name
        phone
      }
    }
  }
`;

export const GET_TODAYS_APPOINTMENT_COUNT = gql`
  query appointment_aggregate($status: String, $appointment_date: date!) {
    appointment_aggregate(
      where: {
        status: { _eq: $status }
        appointment_date: { _eq: $appointment_date }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_COMPANY_DETAILS = gql`
  query company {
    company(order_by: { name: asc }) {
      address
      created_at
      id
      type
      updated_at
      name
    }
  }
`;
export const GET_DEPARTMENT_DETAILS = gql`
  query department {
    department(order_by: { name: asc }) {
      name
      id
      updated_at
      created_at
    }
  }
`;

export const GET_USERNAME = gql`
  query user_by_pk($id: uuid!) {
    user_by_pk(id: $id) {
      name
      role
      email
    }
  }
`;
export const GET_DETAIL = gql`
  query getUserDetails($id: String!) {
    getUserDetails(id: $id) {
      email
      id
      name
      is_password_created
    }
  }
`;

export const GET_CONFIG = gql`
  query configuration {
    configuration {
      id
      name
      value
    }
  }
`;

export const GET_USER_DETAILS_BY_ID = gql`
  query user_by_pk($id: uuid!) {
    user_by_pk(id: $id) {
      company {
        id
        name
      }
      departmentByDepartmentId {
        id
        name
      }
      email
      id
      name
      phone
      created_at
      is_deleted
      is_blocked
    }
  }
`;
export const GET_COMPANY_DETAILS_BY_ID = gql`
  query company_by_pk($id: uuid = "") {
    company_by_pk(id: $id) {
      address
      created_at
      id
      name
      type
      updated_at
    }
  }
`;

export const GET_CONFIG_DETAILS_BY_ID = gql`
  query configuration_by_pk($id: uuid!) {
    configuration_by_pk(id: $id) {
      active
      id
      name
      value
    }
  }
`;

export const GET_DEPT_DETAILS_BY_ID = gql`
  query department_by_pk($id: uuid!) {
    department_by_pk(id: $id) {
      id
      name
    }
  }
`;

export const CHECK_LAST_APPOINTMENT_DATE_FOR_DEPARTMENT = gql`
  query CheckAnyAppointmentInLast15DaysInDept(
    $appointment_date: date = ""
    $last_booked_range_date: date = ""
    $company_id: uuid = ""
    $department_id: uuid = ""
    $status: [String!]
  ) {
    appointment(
      where: {
        appointment_date: {
          _gte: $last_booked_range_date
          _lte: $appointment_date
        }
        user: {
          company_id: { _eq: $company_id }
          department_id: { _eq: $department_id }
        }
        status: { _in: $status }
      }
    ) {
      id
      status
      appointment_date
      user {
        company {
          name
        }
        departmentByDepartmentId {
          name
        }
      }
    }
  }
`;

export const CHECK_APPOINTMENT_EXIST_FOR_DATE = gql`
  query ($status: String = "", $appointment_date: date = "", $user_id: uuid!) {
    appointment(
      where: {
        status: { _eq: $status }
        appointment_date: { _eq: $appointment_date }
        user_id: { _eq: $user_id }
      }
    ) {
      id
    }
  }
`;

export const GET_USER_REG_STATUS = gql`
  query user($company_code: String!) {
    user(where: { company_code: { _eq: $company_code } }) {
      name
      company_name
      email
      id
      created_at
      registration_expired
    }
  }
`;

// APPOINTMENT_COUNT_BY_DATE
export const APPOINTMENT_COUNT_BY_DATE = gql`
  query APPOINTMENT_COUNT_BY_DATE(
    $appointment_date: date = ""
    $status: String = ""
  ) {
    appointment_aggregate(
      where: {
        appointment_date: { _eq: $appointment_date }
        status: { _eq: $status }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const ALLOWED_APPOINTMENTS_FOR_A_DAY = gql`
  query configuration($config_name: String = "%%") {
    configuration(where: { name: { _ilike: $config_name } }) {
      active
      id
      name
      value
    }
  }
`;

export const GET_APPOINTMENT_HISTORY = gql`
  query appointments_history($user_id: uuid = "") {
    appointment(where: { user_id: { _eq: $user_id } }) {
      id
      status
      appointment_date
    }
  }
`;

export const GET_APPOINTMENT_STATUS_BY_USER_ID = gql`
  query appointment(
    $user_id: uuid = ""
    $status: String = ""
    $appointment_date: date = ""
  ) {
    appointment(
      where: {
        user_id: { _eq: $user_id }
        status: { _eq: $status }
        appointment_date: { _gte: $appointment_date }
      }
    ) {
      id
      status
      appointment_date
    }
  }
`;

export const GET_DOCTOR_UNAVAILABILITY = gql`
  query GetDoctorUnavailability {
    doctor_unavailability {
      is_email_sent
      is_sms_sent
      from_date
      to_date
      created_at
      updated_at
      id
    }
  }
`;
