import { Button, Form, Input, Modal } from "antd";
import React from "react";

const AddCompanyModal = ({ visible, onCancel, onSubmit, onLoading }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      title="ADD NEW COMPANY"
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        name="company_form"
        form={form}
        className="company-form"
        onFinish={onSubmit}
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter Company name!",
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="Company Name" />
        </Form.Item>
        <Form.Item
          name="address"
          rules={[
            {
              required: true,
              message: "Please enter Address!",
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="Address" />
        </Form.Item>
        <Form.Item
          name="type"
          rules={[
            {
              required: true,
              message: "Please enter Type!",
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="Type of company" />
        </Form.Item>

        <Form.Item className="text-center ">
          <Button
            type="default"
            shape="round"
            onClick={onCancel}
            className="register-form-back-button"
          >
            Cancel
          </Button>
          <Button
            loading={onLoading}
            type="primary"
            shape="round"
            htmlType="submit"
            className="register-form-button"
          >
            {onLoading ? "Adding..." : "Add"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AddCompanyModal;
