import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardGroup,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { UPDATE_PASSWORD_ON_REG } from "../../graphql/Mutations";
import { GET_DETAIL } from "../../graphql/Queries";
import "./login.css";

const NewPasswordSet = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const [getUserData, { data: userData }] = useLazyQuery(GET_DETAIL);

  const [
    UPDATE_PASSWORD_ON_REG_MUTATION,
    { loading: changingPassword, error: passwordError },
  ] = useMutation(UPDATE_PASSWORD_ON_REG);

  useEffect(() => {
    if (id) {
      getUserData({
        variables: { id },
      });
    }
  }, [getUserData, id]);

  useEffect(() => {
    if (userData) {
      if (userData?.getUserDetails?.is_password_created) {
        navigate("/login");
      }
      setUserEmail(userData?.getUserDetails?.email);
    }
  }, [userData, navigate]);

  const newPasswordSetHandler = async function (user) {
    try {
      setLoading(true);
      const payload = {
        variables: {
          username: userEmail.toLowerCase(),
          password: user.confirm,
        },
      };
      const response = await UPDATE_PASSWORD_ON_REG_MUTATION(payload);
      if (!response.errors && response.data) {
        navigate("/login");
        message.success(response.data.updateUserPasswordRegistration.message);
        setLoading(false);
      } else {
        message.error("Something went wrong please contact admin");
        setLoading(false);
      }
    } catch (err) {
      message.error("Invalid Email/password");
      setLoading(false);
    }
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center min-vh-100 align-items-center">
          <Col md="5">
            <CardGroup className="signin-card">
              <Card>
                <CardHeader>
                  <h5 className="py-2 text-center mb-0">Set new password</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    name="set_password_form"
                    form={form}
                    className="login-form"
                    onFinish={newPasswordSetHandler}
                  >
                    <div className="text-center"> &nbsp;</div>

                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          whitespace: true,
                          message: "Password should not be empty!",
                        },
                      ]}
                    >
                      <Input.Password placeholder="Password" />
                    </Form.Item>

                    <Form.Item
                      name="confirm"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        {
                          whitespace: true,
                          message: "Password should not be empty!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder="Confirm Password" />
                    </Form.Item>

                    <Form.Item className="text-center">
                      <Button
                        loading={loading}
                        shape="round"
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </Button>
                    </Form.Item>
                  </Form>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default NewPasswordSet;
