import { MailOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Button, DatePicker, Form, message, Space, Spin, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { INSERT_DOCTOR_UNAVAILABLE_ONE } from "../../graphql/Mutations";
import SendEmailModalMessage from "./SendEmailModalMessage";
import { GET_DOCTOR_UNAVAILABILITY } from "../../graphql/Queries";
import "./Availability.css";
import { getLocalStorageAccessToken } from "../../Utils/common-utils";

const { REACT_APP_API } = process.env;
const { Column } = Table;

function Availability() {
  const {
    loading: compDataLoading,
    data: doctorUnavailabilityData,
    error: compDataError,
    refetch,
  } = useQuery(GET_DOCTOR_UNAVAILABILITY);

  const [
    addDoctorUnavailableEntry,
    { loading: registering, error: registerError },
  ] = useMutation(INSERT_DOCTOR_UNAVAILABLE_ONE);

  const [form] = Form.useForm();
  const [doctorLeaveData, setDoctorLeaveData] = useState([]);
  const [showEmailMessageModal, setEmailShowMessageModal] = useState(false);
  const [isEmailSentLoading, setIsEmailSentLoading] = useState(false);

  const onLeaveFormSubmit = (values) => {
    if (!values?.fromDate) {
      return alert("Please select a dates properly");
    }
    try {
      const response = addDoctorUnavailableEntry({
        variables: {
          from_date: values.fromDate,
          to_date: values.toDate,
        },
      });
      if (!response.error) {
        message.success("Leaves added successfully");
        refetch();
      } else {
        message.error("Invalid Leaves data, Please contact support");
      }
    } catch (err) {
      message.error("Invalid Leaves data, Please contact support");
    }
    form.resetFields();
  };

  const calculateDays = (fromDate, toDate) => {
    const momentFromDate = moment(fromDate);
    const momentToDate = moment(toDate);
    const diffTime = Math.abs(momentToDate - momentFromDate) + 1;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const handleSendEmailClick = async (record) => {
    if (record?.is_email_sent) {
      alert("Emails has been sent Already, Do you want to send email again?");
    } else {
      const payload = {
        docUnavailableId: record.id,
      };
      // TODO: call mutation to send email
      const response = await fetch(
        `${REACT_APP_API}/rest/sendDoctorUnavailabilityMail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageAccessToken()}`,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      if (data?.error) {
        message.error(data?.error);
        return;
      }
      message.success("Email sent successfully");
      refetch();
    }
  };

  return (
    <>
      <h1>Leave Dates</h1>
      <Space style={{ border: "1px solid black", margin: 10, padding: 20 }}>
        <Form name="leave_form" form={form} onFinish={onLeaveFormSubmit}>
          <Form.Item>
            <h3>Set your leaves date here</h3>
          </Form.Item>
          <Form.Item
            name="fromDate"
            label="From Date"
            rules={[
              {
                required: true,
                message: "Please enter from Date!",
              },
            ]}
          >
            <DatePicker format={"DD MMM YYYY"} name="fromDate" />
          </Form.Item>
          <Form.Item
            name="toDate"
            label="To Date"
            rules={[
              {
                required: true,
                message: "Please enter to Date!",
              },
            ]}
          >
            <DatePicker format={"DD MMM YYYY"} name="toDate" />
          </Form.Item>
          <Button type="primary" htmlType="submit" shape="round">
            Add
          </Button>
        </Form>
      </Space>
      {!doctorUnavailabilityData ? (
        <Spin />
      ) : (
        <div style={{ marginTop: "20px", border: "1px solid black" }}>
          <Table
            bordered={true}
            loading={compDataLoading || isEmailSentLoading}
            dataSource={doctorUnavailabilityData.doctor_unavailability}
            rowKey={(record) => record.id}
          >
            <Column
              title="Leave Date"
              key="leaveDate"
              render={(record) =>
                `${moment(record.from_date).format("Do MMM YYYY")} -
              ${moment(record.to_date).format("Do MMM YYYY")}`
              }
            />
            <Column
              title="Leave Days Count"
              key="leavesDayCount"
              render={(record) =>
                calculateDays(record.from_date, record.to_date)
              }
            />
            <Column
              title="Email Sent"
              key=""
              dataIndex="is_email_sent"
              render={(record) => (record ? "Yes" : "No")}
            />
            <Column
              title="SMS Sent"
              key="smsSent"
              dataIndex="is_sms_sent"
              render={(record) => (record ? "Yes" : "No")}
            />
            <Column
              title="Created Date"
              key="created_date"
              render={(record) =>
                moment(record.created_at).format("Do MMMM YYYY")
              }
            />
            <Column
              title="Action"
              key="action"
              render={(record) => (
                <Space>
                  <Button
                    shape="round"
                    style={{ backgroundColor: "teal", color: "white" }}
                    icon={<MailOutlined />}
                    onClick={() => handleSendEmailClick(record)}
                  >
                    Send Email
                  </Button>
                </Space>
              )}
            />
          </Table>
        </div>
      )}
      <SendEmailModalMessage
        onCancel={() => {
          setEmailShowMessageModal(false);
        }}
        onLoading={() => {}}
        onSubmit={() => {}}
        visible={showEmailMessageModal}
      />
    </>
  );
}

export default Availability;
