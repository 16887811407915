import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Input, message, Select, Space, Table } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import ConfirmationModal from "../../components/ConfirmationModal";
import {
  ATTEND_MULTIPLE_APPOINTMENTS,
  DELETE_MULTIPLE_APPOINTMENTS,
} from "../../graphql/Mutations";
import { GET_ALL_APPOINEMENTS } from "../../graphql/Queries";
import "./Appointment.css";
import { APPOINTMENT_STATUS } from "../../Constants/common-constants";

const { Column } = Table;
const { Search } = Input;
const { Option } = Select;

const Appointments = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [singleAppointment, setSingleAppointment] = useState([]);
  const [singleUser, setSingleUser] = useState("");
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenAttendModal, setIsOpenAttendModal] = useState(false);
  const [reportFilterRange, setReportFilterRange] = React.useState({
    startDate: moment().startOf("day").format("YYYY-MM-DD"),
    endDate: moment().endOf("day").format("YYYY-MM-DD"),
  });

  const [
    GET_ALL_APPOINEMENTS_QUERY,
    { loading: appointmentsLoading, data: appointmentsData },
  ] = useLazyQuery(GET_ALL_APPOINEMENTS, {
    fetchPolicy: "network-only",
  });

  const getAllAppointments = useCallback(() => {
    GET_ALL_APPOINEMENTS_QUERY({
      variables: {
        appointmentStartDate: reportFilterRange.startDate ?? undefined,
        appointmentEndDate: reportFilterRange.endDate ?? undefined,
      },
    });
  }, [
    GET_ALL_APPOINEMENTS_QUERY,
    reportFilterRange.endDate,
    reportFilterRange.startDate,
  ]);

  useEffect(() => {
    getAllAppointments();
  }, [getAllAppointments]);

  const [deleteAppointments, { loading: deleting }] = useMutation(
    DELETE_MULTIPLE_APPOINTMENTS
  );

  const [attendedAppointments, { loading: attending }] = useMutation(
    ATTEND_MULTIPLE_APPOINTMENTS
  );

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;
  const hasSingleUser = singleAppointment.length > 0;

  const handleDeleteAppointment = async () => {
    isOpenDeleteModal(false);
    if (hasSelected || hasSingleUser) {
      const response = await deleteAppointments({
        variables: {
          ids: selectedRowKeys || singleAppointment,
        },
      });
      if (!response.error) {
        message.success("Appointments deleted successfully");
        getAllAppointments();
        setSelectedRowKeys([]);
      } else {
        message.error("Something went wrong, please contact support");
      }
    }
  };

  const handleSearch = (searchInput) => {
    GET_ALL_APPOINEMENTS_QUERY({
      variables: {
        name: `%${searchInput}%`,
        appointmentStartDate: reportFilterRange.startDate ?? undefined,
        appointmentEndDate: reportFilterRange.endDate ?? undefined,
      },
    });
  };

  const handleAttendAppointment = async () => {
    setIsOpenAttendModal(false);
    if (singleUser) {
      const response = await attendedAppointments({
        variables: {
          id: singleUser,
          status: APPOINTMENT_STATUS.ATTENDED,
        },
      });
      if (!response.error) {
        message.success("Appointments mark attended successfully");
        getAllAppointments();
        setSelectedRowKeys([]);
      } else {
        message.error("Something went wrong, please contact support");
      }
    }
    isOpenAttendModal(false);
  };

  const renderActionButtons = (record) => {
    return (
      <Space>
        <Button
          shape="round"
          type="primary"
          onClick={() => {
            setIsOpenEditModal(true);
          }}
        >
          Edit
        </Button>
        <Space />
        <Button
          shape="round"
          type="danger"
          onClick={() => {
            setSingleAppointment(record.id, ...singleAppointment);
            setIsOpenDeleteModal(true);
          }}
          disabled={record.status === APPOINTMENT_STATUS.ATTENDED}
        >
          Delete
        </Button>
        <Button
          shape="round"
          type="dashed"
          onClick={() => {
            setSingleUser(record.id);
            setIsOpenAttendModal(true);
          }}
          disabled={record.status === APPOINTMENT_STATUS.ATTENDED}
        >
          {record.status === APPOINTMENT_STATUS.ATTENDED
            ? "Attended"
            : "Attend"}
        </Button>
      </Space>
    );
  };

  return (
    <>
      <div className="top-filters">
        <Search
          className="searchbar"
          onSearch={(value) => handleSearch(value)}
          placeholder="Search"
          allowClear
        />
        <Select
          placeholder="Date Filter"
          className="date-dropdown"
          onChange={(value) => {
            setReportFilterRange(JSON.parse(value));
          }}
          value={JSON.stringify(reportFilterRange)}
        >
          <Option
            value={JSON.stringify({
              startDate: moment().startOf("day").format("YYYY-MM-DD"),
              endDate: moment().endOf("day").format("YYYY-MM-DD"),
            })}
          >
            Today
          </Option>
          <Option
            value={JSON.stringify({
              startDate: moment()
                .startOf("day")
                .add(1, "day")
                .format("YYYY-MM-DD"),
              endDate: moment()
                .startOf("day")
                .add(7, "day")
                .format("YYYY-MM-DD"),
            })}
          >
            Next 7 days
          </Option>
          <Option
            value={JSON.stringify({
              startDate: moment()
                .startOf("day")
                .subtract(7, "day")
                .format("YYYY-MM-DD"),
              endDate: moment().startOf("minute").format("YYYY-MM-DD"),
            })}
          >
            Last 7 Days
          </Option>
          <Option
            value={JSON.stringify({
              startDate: moment()
                .startOf("day")
                .subtract(15, "days")
                .format("YYYY-MM-DD"),
              endDate: moment().startOf("minute").format("YYYY-MM-DD"),
            })}
          >
            Last 15 Days
          </Option>
          <Option
            value={JSON.stringify({
              startDate: moment()
                .startOf("day")
                .subtract(1, "months")
                .format("YYYY-MM-DD"),
              endDate: moment().startOf("minute").format("YYYY-MM-DD"),
            })}
          >
            Last 1 Month
          </Option>
          <Option
            value={JSON.stringify({
              startDate: moment()
                .startOf("day")
                .subtract(3, "months")
                .format("YYYY-MM-DD"),
              endDate: moment().startOf("minute").format("YYYY-MM-DD"),
            })}
          >
            Last 3 Months
          </Option>
        </Select>
        {hasSelected && (
          <Button
            onClick={() => handleDeleteAppointment()}
            shape="round"
            type="danger"
          >
            Delete selected users
          </Button>
        )}
      </div>

      <div>
        <Table
          bordered={true}
          loading={appointmentsLoading || deleting || attending}
          dataSource={appointmentsData?.appointment}
          rowSelection={rowSelection}
          rowKey={(record) => record.id}
          pagination={false}
        >
          <Column
            title="MR Name"
            key="user"
            dataIndex="user"
            render={(record) => record.name}
          />
          <Column
            title="Appointment Date"
            key="appointment_date"
            dataIndex="appointment_date"
            render={(record) => moment(record).format("Do MMMM YYYY")}
          />
          <Column
            title="Status"
            key="appointment-status"
            render={(record) => (record ? record?.status.toUpperCase() : "")}
          />
          <Column
            title="Created Date"
            key="created_date"
            render={(record) =>
              moment(record.created_at).format("Do MMMM YYYY")
            }
          />
          <Column
            title="Company"
            key="company"
            render={(record) => (record ? record?.user?.company?.name : "")}
          />
          <Column
            title="Department"
            key="department"
            render={(record) =>
              record ? record?.user?.departmentByDepartmentId?.name : ""
            }
          />
          <Column
            title="Action"
            key="action"
            render={(record) => renderActionButtons(record)}
          />
        </Table>
      </div>
      <ConfirmationModal
        title="Confirm Delete"
        description="Are you sure you want to delete?"
        visible={isOpenDeleteModal}
        onHandleSubmit={() => handleDeleteAppointment()}
        onCancel={() => setIsOpenDeleteModal(false)}
      />
      <ConfirmationModal
        title="Confirm Attend"
        description="Are you sure you want to mark attend?"
        visible={isOpenAttendModal}
        onHandleSubmit={handleAttendAppointment}
        onCancel={() => setIsOpenAttendModal(false)}
      />
    </>
  );
};

export default Appointments;
