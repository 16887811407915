import { useQuery } from "@apollo/client";
import { Button, Form, Input, Modal, Spin } from "antd";
import React, { useEffect } from "react";
import { GET_CONFIG_DETAILS_BY_ID } from "../../graphql/Queries";

const EditConfig = ({ visible, onCancel, onSubmit, onLoading, configId }) => {
  const [form] = Form.useForm();

  const { loading: configDataLoading, data: configData } = useQuery(
    GET_CONFIG_DETAILS_BY_ID,
    {
      variables: {
        id: configId,
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (configData && !configDataLoading) {
      const { name, value } = configData.configuration_by_pk;
      form.setFieldsValue({
        name,
        value,
      });
    }
  }, [form, configData, configDataLoading]);

  return (
    <Modal
      title="UPDATE CONFIGURATION"
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      {configDataLoading ? (
        <Spin />
      ) : (
        <Form
          name="config_form"
          form={form}
          className="config-form"
          onFinish={onSubmit}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter config name!",
              },
            ]}
            className="mb-3"
          >
            <Input placeholder="Config Name" />
          </Form.Item>
          <Form.Item
            name="value"
            rules={[
              {
                required: true,
                message: "Please enter config value!",
              },
            ]}
            className="mb-3"
          >
            <Input placeholder="Config value" />
          </Form.Item>
          <Form.Item className="text-center">
            <Button
              type="default"
              shape="round"
              onClick={onCancel}
              className="register-form-back-button"
            >
              Cancel
            </Button>
            <Button
              loading={onLoading}
              type="primary"
              shape="round"
              htmlType="submit"
              className="register-form-button"
            >
              {onLoading ? "Updating..." : "Update"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
export default EditConfig;
