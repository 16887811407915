import { useQuery } from "@apollo/client";
import { Button, Form, Input, InputNumber, Modal, Select } from "antd";
import React, { useEffect } from "react";
import {
  GET_COMPANY_DETAILS,
  GET_DEPARTMENT_DETAILS,
} from "../../graphql/Queries";

const AddUsersModal = ({
  visible,
  onCancel,
  onSubmit,
  onLoading,
  loading,
  form,
}) => {
  const { Option } = Select;
  const {
    loading: compDataLoading,
    data: compData,
    error: compDataError,
  } = useQuery(GET_COMPANY_DETAILS);

  const {
    loading: deptDataLoading,
    data: deptData,
    error: deptDataError,
  } = useQuery(GET_DEPARTMENT_DETAILS);

  if (compDataError || deptDataError) {
    console.log("compDataError", compDataError);
    console.log("deptDataError", deptDataError);
  }

  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <Modal
      title="Register a new user"
      open={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        name="normal_login"
        form={form}
        className="login-form"
        onFinish={onSubmit}
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter Full-Name!",
            },
            {
              whitespace: true,
              message: "Full-Name cannot be empty!",
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="Full-Name" />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Please enter your Phone Number!",
            },
          ]}
          className="mb-3"
        >
          <InputNumber
            className="w-100"
            controls={false}
            placeholder="Phone Number"
            maxLength={10}
            minLength={10}
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your Email!",
            },
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item name="department" className="mb-3">
          <Select
            allowClear
            loading={deptDataLoading}
            placeholder="Select Department"
            showSearch
            placement="bottomLeft"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {deptData && deptData?.department?.length > 0
              ? deptData?.department?.map((dept) => {
                  return <Option value={dept.id}>{dept?.name}</Option>;
                })
              : "No Data"}
          </Select>
        </Form.Item>
        <Form.Item name="company" className="mb-3">
          <Select
            allowClear
            loading={compDataLoading}
            placeholder="Select company"
            showSearch
            placement="bottomLeft"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {compData && compData.company.length > 0
              ? compData?.company?.map((comp) => {
                  return <Option value={comp.id}>{comp.name}</Option>;
                })
              : "No Data"}
          </Select>
        </Form.Item>
        <Form.Item className="text-center">
          <Button
            type="default"
            shape="round"
            onClick={onCancel}
            className="register-form-back-button"
          >
            Cancel
          </Button>
          <Button
            loading={onLoading && loading}
            type="primary"
            shape="round"
            htmlType="submit"
            className="register-form-button"
          >
            {"Register"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AddUsersModal;
