import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import NewPasswordSet from "./pages/Login/NewPasswordSet";
import routes from "./routes/Routes";
import { PrivateRoute } from "./Utils/PrivateRoutes";
import { adminRolesRoutes } from './Constants/common-constants';
import { getLocalStorageAccessToken } from './Utils/common-utils';

const App = () => {

  const roleWiseRoute = () => {
    const role = localStorage.getItem("user_role");
    return <Navigate to={adminRolesRoutes.find((item) => item.role.includes(role))?.path} />
  };

  const routePath = () => {
    const user = !!getLocalStorageAccessToken();
    return user ? roleWiseRoute() : (<Navigate to="/login" replace />);
  };

  return (
    <Routes>
      <Route path="/" element={routePath()} />
      <Route path="login" element={<Login />} />
      <Route path="set-password/:id" element={<NewPasswordSet />} />
      {routes.map((route, index) => (
        <Route
          key={route.path}
          path={route.path}
          element={<PrivateRoute>{route.element}</PrivateRoute>}
        />
      ))}
    </Routes>
  );
};
export default App;
