import { UserAddOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Form, message, Space, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import AddUsersModal from "./AddUsersModal";
import EditUser from "./EditUser";
import {
  REGISTER_USER,
  DELETE_USER,
  BLOCK_USER,
} from "../../graphql/Mutations";
import { GET_USER_DETAILS } from "../../graphql/Queries";
import ConfirmationModal from "../../components/ConfirmationModal";

const { Column } = Table;

function Users() {
  const [form] = Form.useForm();
  const org_id = localStorage.getItem("org_id");
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);
  const [isBlockUserOpen, setIsBlockUserOpen] = useState(false);
  const [isUnBlockUserOpen, setIsUnBlockUserOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");

  const {
    loading: dataLoading,
    data: userData,
    refetch,
  } = useQuery(GET_USER_DETAILS);

  const [registerUser, { loading: registering, error: registerError }] =
    useMutation(REGISTER_USER);
  const [deleteUser, { loading: deleting, error: deleteError }] =
    useMutation(DELETE_USER);
  const [blockUser, { loading: blocking, error: blockError }] =
    useMutation(BLOCK_USER);

  if (registerError) {
    console.log("registerError", registerError);
  }
  if (deleteError) {
    console.log("deleteError", deleteError);
  }

  const submitHandler = async (user) => {
    try {
      const response = await registerUser({
        variables: {
          name: user.name.trim(),
          phone: `${user.phone}`,
          email: user.email.trim(),
          department_id: user.department,
          company_id: user.company,
          role: "user",
          org_id,
        },
      });
      if (response.data.register.status === "OK") {
        message.success(`${response.data.register.message}`);
        refetch();
        setIsAddUserOpen(false);
        setLoading(false);
        form.resetFields();
      } else {
        response.errors.forEach((e) => {
          return message.error(e.message);
        });
        setIsAddUserOpen(false);
        setLoading(false);
      }
    } catch (err) {
      message.error("Invalid User data");
      setIsAddUserOpen(false);
      setLoading(false);
    }
  };

  const handleEditUser = (user) => {
    setUserId(user.id);
    setIsEditUserOpen(true);
  };

  const handleDeleteConfirm = (user) => {
    setUserId(user.id);
    setIsDeleteUserOpen(true);
  };

  const handleBlockConfirm = (user) => {
    setUserId(user.id);
    if (user.is_blocked) {
      setIsUnBlockUserOpen(true);
    } else {
      setIsBlockUserOpen(true);
    }
  };

  const handleDeleteUser = async () => {
    setIsDeleteUserOpen(false);
    try {
      const response = await deleteUser({
        variables: {
          id: userId,
        },
      });
      if (!response.error) {
        message.success("User Deleted successfully");
        refetch();
      } else {
        message.error("Invalid User data");
      }
    } catch (err) {
      message.error("Invalid User data");
    }
    setUserId("");
  };

  const handleBlockUser = async () => {
    setIsBlockUserOpen(false);
    setIsUnBlockUserOpen(false);
    try {
      const response = await blockUser({
        variables: {
          id: userId,
          is_blocked: isBlockUserOpen ? true : false,
        },
      });
      if (!response.error) {
        message.success(
          `User ${isBlockUserOpen ? "Blocked" : "Unblocked"} successfully`
        );
        refetch();
      } else {
        message.error("Invalid User data");
      }
    } catch (err) {
      message.error("Invalid User data");
    }
    setUserId("");
  };

  return (
    <div className="animated fadeIn">
      <AddUsersModal
        visible={isAddUserOpen}
        onCancel={() => {
          setIsAddUserOpen(false);
          form.resetFields();
        }}
        onSubmit={submitHandler}
        onLoading={registering}
        loading={loading}
        form={form}
      />
      {isEditUserOpen && userId && (
        <EditUser userId={userId} onCancel={() => setIsEditUserOpen(false)} />
      )}

      <ConfirmationModal
        title="Confirm Delete"
        description="Are you sure you want to delete?"
        visible={isDeleteUserOpen}
        onHandleSubmit={() => handleDeleteUser()}
        onCancel={() => setIsDeleteUserOpen(false)}
      />

      <ConfirmationModal
        title={`Confirm ${isUnBlockUserOpen ? "Unblock" : "Block"}`}
        description={`Are you sure you want to ${
          isUnBlockUserOpen ? "Unblock" : "Block"
        }`}
        visible={isBlockUserOpen || isUnBlockUserOpen}
        onHandleSubmit={handleBlockUser}
        onCancel={() => {
          setIsBlockUserOpen(false);
          setIsUnBlockUserOpen(false);
        }}
      />

      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader>
              <Button
                className="register-button d-inline-flex align-items-center"
                type="primary"
                size="large"
                shape="round"
                icon={<UserAddOutlined />}
                onClick={() => {
                  setIsAddUserOpen(true);
                }}
              >
                Register User
              </Button>
            </CardHeader>
            <CardBody>
              <Table
                bordered={true}
                loading={dataLoading}
                dataSource={userData?.user}
                rowKey={(record) => record.id}
                pagination={false}
              >
                <Column
                  title="Name"
                  key="name"
                  dataIndex="name"
                  sorter={(a, b) => a.name.length - b.name.length}
                />
                <Column title="Email" key="email" dataIndex="email" />
                <Column title="Phone" key="phone" dataIndex="phone" />
                <Column
                  title="Created Date"
                  key="created_date"
                  render={(record) =>
                    moment(record.created_at).format("Do MMMM YYYY")
                  }
                  sorter={(a, b) => a.created_at.length - b.created_at.length}
                />
                <Column
                  title="Company"
                  key="company"
                  render={(record) =>
                    record.company ? record.company.name : ""
                  }
                />
                <Column
                  title="Department"
                  key="department"
                  render={(record) =>
                    record.departmentByDepartmentId
                      ? record.departmentByDepartmentId.name
                      : ""
                  }
                />
                <Column
                  title="Action"
                  key="action"
                  render={(record) => (
                    <Space>
                      <Button
                        shape="round"
                        type="primary"
                        onClick={() => handleEditUser(record)}
                      >
                        Edit
                      </Button>
                      <Space />
                      <Button
                        loading={record.id === userId && deleting}
                        shape="round"
                        type="primary"
                        danger
                        ghost
                        onClick={() => {
                          handleDeleteConfirm(record);
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        loading={record.id === userId && blocking}
                        shape="round"
                        type="dashed"
                        danger
                        onClick={() => {
                          handleBlockConfirm(record);
                        }}
                        className={record.is_blocked ? "bg-danger" : ""}
                      >
                        {record.is_blocked ? "Blocked" : "Block"}
                      </Button>
                    </Space>
                  )}
                />
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Users;
