import { Spin } from "antd";
import React from "react";
import "./Loader.css";

const FullLoader = () => {
  return (
    <div className="fullLoader">
      <Spin />
    </div>
  );
};

export default FullLoader;
