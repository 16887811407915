import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Row,
  CardHeader,
} from "reactstrap";
import { Form, Input, Button, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { LOGIN_MUTATION } from "../../graphql/Mutations";
import { localStorageUtils } from "../../Utils/storage";
import { LOGIN_LOCAL_STORAGE_KEY } from "../../Constants/localStorageKeys";
import { adminRolesRoutes } from "../../Constants/common-constants";
import "./login.css";

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [login] = useMutation(LOGIN_MUTATION);

  const handleLoginSubmit = async (user) => {
    try {
      setLoading(true);
      if (user.remember) {
        localStorageUtils.setItem(
          LOGIN_LOCAL_STORAGE_KEY,
          JSON.stringify(user)
        );
      } else {
        localStorageUtils.removeItem(LOGIN_LOCAL_STORAGE_KEY);
      }

      const payload = {
        variables: {
          username: user.email.toLowerCase(),
          password: user.password,
        },
      };

      const response = await login(payload);
      if (!response.errors && response.data) {
        const roleBasedNavigationPath = adminRolesRoutes.find((item) =>
          item.role.includes(response.data.login.role)
        )?.path;
        navigate(roleBasedNavigationPath);
        setLocalStorage(user, response);
        setLoading(false);
      } else {
        form.setFieldValue("password", null);
        setLoading(false);
      }
    } catch (err) {
      message.error("Invalid Email/password");
      setLoading(false);
    }
  };

  const setLocalStorage = (user, response) => {
    const { access_token, role, id, org_id } = response.data.login;
    localStorageUtils.setItem("username", user.email.toLowerCase());
    localStorageUtils.setItem("access_token", access_token);
    localStorageUtils.setItem("user_role", role);
    localStorageUtils.setItem("user_id", id);
    localStorageUtils.setItem("org_id", org_id);
  };

  const handleRememberMe = (event) => {
    if (event.target.checked) {
      const formData = {
        email: form.getFieldValue("email"),
        password: form.getFieldValue("password"),
        remember: form.getFieldValue("remember"),
      };
      localStorageUtils.setItem(
        LOGIN_LOCAL_STORAGE_KEY,
        JSON.stringify(formData)
      );
    } else {
      localStorageUtils.removeItem(LOGIN_LOCAL_STORAGE_KEY);
    }
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center min-vh-100 align-items-center">
          <Col md="5">
            <CardGroup className="signin-card">
              <img
                className="rajlogo-signin"
                alt="Raj Clinic Logo"
                src={require("../../icons/rajclinic.jpeg")}
              />
              <Card>
                <CardHeader>
                  <h5 className="py-2 text-center mb-0">Sign In</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    name="normal_login"
                    form={form}
                    className="login-form"
                    onFinish={handleLoginSubmit}
                  >
                    <div className="text-center"> &nbsp;</div>

                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Email!",
                        },
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                      ]}
                      className="mb-3"
                    >
                      <Input
                        size="large"
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="Email"
                      />
                    </Form.Item>
                    <Form.Item
                      className="mb-3"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Password!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Password"
                      />
                    </Form.Item>
                    <div className="d-flex mb-4">
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        noStyle
                        className="me-auto"
                      >
                        <Checkbox onChange={handleRememberMe}>
                          Remember me
                        </Checkbox>
                      </Form.Item>

                      <Link
                        to="/forgot-password"
                        className="login-form-forgot ms-auto"
                      >
                        Forgot password
                      </Link>
                    </div>

                    <Form.Item className="text-center">
                      <Button
                        loading={loading}
                        shape="round"
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        {loading ? "Loading..." : "Log in"}
                      </Button>
                    </Form.Item>
                  </Form>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
