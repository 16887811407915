import { Button, Form, Input, Modal } from "antd";
import React from "react";

const AddDepartment = ({ visible, onCancel, onSubmit, onLoading }) => {
  const [form] = Form.useForm();
  form.resetFields();

  return (
    <Modal
      title="ADD NEW DEPARTMENT"
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        name="department_form"
        form={form}
        className="department-form"
        onFinish={onSubmit}
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter department name!",
            },
          ]}
          className="mb-3"
        >
          <Input placeholder="Department Name" />
        </Form.Item>
        <Form.Item className="text-center">
          <Button
            type="default"
            shape="round"
            onClick={onCancel}
            className="register-form-back-button"
          >
            Cancel
          </Button>
          <Button
            loading={onLoading}
            type="primary"
            shape="round"
            htmlType="submit"
            className="register-form-button"
          >
            {onLoading ? "Adding..." : "Add"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AddDepartment;
