import { useQuery } from "@apollo/client";
import { Button, Form, Input, Modal, Spin } from "antd";
import React, { useEffect } from "react";
import { GET_COMPANY_DETAILS_BY_ID } from "../../graphql/Queries";

const EditCompany = ({ visible, onCancel, onSubmit, onLoading, companyId }) => {
  const [form] = Form.useForm();

  const { loading: compDataLoading, data: compData } = useQuery(
    GET_COMPANY_DETAILS_BY_ID,
    {
      variables: {
        id: companyId,
      },
    }
  );

  useEffect(() => {
    if (compData) {
      const { name, address, type } = compData.company_by_pk;
      form.setFieldsValue({
        name,
        address,
        type,
      });
    }
  }, [form, compData]);

  return (
    <Modal
      title="EDIT COMPANY DETAILS"
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      {compDataLoading ? (
        <Spin />
      ) : (
        compData && (
          <Form
            name="company_form"
            form={form}
            className="company-form"
            onFinish={onSubmit}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter Company name!",
                },
              ]}
              className="mb-3"
            >
              <Input placeholder="Company Name" />
            </Form.Item>
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please enter Address!",
                },
              ]}
              className="mb-3"
            >
              <Input placeholder="Address" />
            </Form.Item>
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please enter Type!",
                },
              ]}
              className="mb-3"
            >
              <Input placeholder="Type of company" />
            </Form.Item>

            <Form.Item className="text-center ">
              <Button
                type="default"
                shape="round"
                onClick={onCancel}
                className="register-form-back-button"
              >
                Cancel
              </Button>
              <Button
                loading={onLoading}
                type="primary"
                shape="round"
                htmlType="submit"
                className="register-form-button"
              >
                {onLoading ? "Saving..." : "Save"}
              </Button>
            </Form.Item>
          </Form>
        )
      )}
    </Modal>
  );
};
export default EditCompany;
