import { Modal } from "antd";
import React from "react";

const ConfirmationModal = ({
  visible,
  onHandleSubmit,
  onCancel,
  title,
  description,
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={onHandleSubmit}
      onCancel={onCancel}
      okText="Confirm"
      cancelText="Cancel"
    >
      {description}
    </Modal>
  );
};

export default ConfirmationModal;
