export const CONFIG_CONSTANTS = {
    GAP_BETWEEN_APPOINTMENTS: "GapBetweenAppointments",
    LAST_BOOKING_TIME_FOR_CURRENT_DAY: "LastBookingTimeForCurrentDay",
    ALLOWED_MEETING_CANCELLATION : "AllowedMeetingCancellationTimeInHours"
};

export const adminRolesRoutes = [{
    role: ["admin", "org_admin"],
    path: "/dashboard",
},
{
    role: ["user"],
    path: "/appointment-status",
}
];

export const APPOINTMENT_STATUS = {
    BOOKED: "booked",
    ATTENDED: "attended",
    CANCELLED: "cancelled",
};
