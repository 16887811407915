import {
  ClockCircleOutlined,
  LineChartOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  ShoppingOutlined,
  UserOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Drawer, Layout } from "antd";
import { Footer } from "antd/lib/layout/layout";
import React, { useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { GET_USERNAME } from "../../graphql/Queries";
import "./AppLayout.css";
import DrawerContent from "./DrawerContent";
const { Header, Content } = Layout;

function AppLayout({ children }) {
  const [openMenu, setOpenMenu] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const userRole = localStorage.getItem("user_role");
  const navigate = useNavigate();

  const openMenuDrawer = () => {
    setOpenMenu(true);
  };
  const closeMenuDrawer = () => setOpenMenu(false);

  const { data: userData } = useQuery(GET_USERNAME, {
    variables: {
      id: user_id,
    },
  });

  const adminNavMenuItems = [
    {
      key: "dashboard",
      icon: <LineChartOutlined />,
      label: `Dashboard`,
      onClick: () => {
        setOpenMenu(false);
        navigate("/dashboard");
      },
    },
    {
      key: "appointments",
      icon: <ClockCircleOutlined />,
      label: `Appointments`,
      onClick: () => {
        setOpenMenu(false);
        navigate("/appointments");
      },
    },
    {
      key: "users",
      icon: <UserOutlined />,
      label: `Users`,
      onClick: () => {
        setOpenMenu(false);
        navigate("/users");
      },
    },
    {
      key: "company",
      icon: <ShoppingOutlined />,
      label: `Company`,
      onClick: () => {
        setOpenMenu(false);
        navigate("/company");
      },
    },
    {
      key: "department",
      icon: <ShoppingOutlined />,
      label: `Department`,
      onClick: () => {
        setOpenMenu(false);
        navigate("/department");
      },
    },
    {
      key: "configuration",
      icon: <SettingOutlined />,
      label: `Configuration`,
      onClick: () => {
        setOpenMenu(false);
        navigate("/configuration");
      },
    },
    {
      key: "doctor-avail",
      icon: <CalendarOutlined />,
      label: `Availability`,
      onClick: () => {
        setOpenMenu(false);
        navigate("/availability");
      },
    },
  ];

  const userNavMenuItems = [
    {
      key: "appointment-status",
      icon: <ClockCircleOutlined />,
      label: `Appointment Status`,
      onClick: () => {
        setOpenMenu(false);
        navigate("/appointment-status");
      },
    },
    {
      key: "appointment-history",
      icon: <ClockCircleOutlined />,
      label: `Appointment History`,
      onClick: () => {
        setOpenMenu(false);
        navigate("/appointment-history");
      },
    },
  ];

  const navMenuItems =
    userRole === "admin" || userRole === "org_admin"
      ? adminNavMenuItems
      : userNavMenuItems;

  const handleLogoutClick = useCallback(() => {
    setOpenMenu(false);
    localStorage.clear();
    navigate("/login");
  }, [navigate]);

  return (
    <Layout className="user-layout">
      <Header className="header d-flex">
        <div className="logo_blk">
          <div className="menu">
            <MenuUnfoldOutlined onClick={() => openMenuDrawer()} />
            <img
              className="rajlogo"
              alt="Rajclinic Logo"
              src={require("../../icons/rajclinic.jpeg")}
            />
          </div>
        </div>
        <div className="ms-auto header-right">
          <div
            className="headerProfile"
            onClick={() => navigate("/my-profile")}
          >
            Hello, {userData?.user_by_pk.name ?? undefined}
          </div>
        </div>
      </Header>
      <Layout className="wrapper">
        <Drawer
          placement="left"
          closable={false}
          onClose={closeMenuDrawer}
          open={openMenu}
          key="left"
          className="sideMenu"
          width={280}
        >
          <DrawerContent
            navMenuItems={navMenuItems}
            handleLogoutClick={handleLogoutClick}
            closeMenuDrawer={closeMenuDrawer}
            userName={userData?.user_by_pk.name}
          ></DrawerContent>
        </Drawer>
        <Content className="layout-content">{children}</Content>
        <Footer className="text-center">
          Raj Clinic ©{new Date().getFullYear()} Created by Juyushi Software
        </Footer>
      </Layout>
    </Layout>
  );
}

export default AppLayout;
