import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloLink, split } from "apollo-link";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { message } from "antd";
import { getLocalStorageAccessToken } from "../common-utils";
const { REACT_APP_GQL_URL } = process.env;

const cache = new InMemoryCache();

const getBearerToken = (token) => (token ? `Bearer ${token}` : null);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(
      ({ message: errorMessage, statusCode, path, extensions }) => {
        if (extensions?.code === "access-denied") {
          message.error(errorMessage);
          localStorage.clear();
        } else {
          if (path && statusCode === 401) {
            message.error(errorMessage);
            localStorage.clear();
          }
        }
        return null;
      }
    );
  }

  if (networkError) {
    console.log("networkError", networkError);
  }
});

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const accessToken = getLocalStorageAccessToken();

  // Use the setContext method to set the HTTP headers.
  if (accessToken) {
    operation.setContext({
      headers: {
        authorization: getBearerToken(accessToken),
        ...operation.getContext().headers,
      },
      http: {
        includeExtensions: true,
        includeQuery: true,
      },
    });
  } else {
    operation.setContext({
      headers: {
        "x-hasura-endpoint-type": "public",
        ...operation.getContext().headers,
      },
      http: {
        includeExtensions: true,
        includeQuery: true,
      },
    });
  }
  return forward(operation);
});

const httpLink = new createHttpLink({
  uri: "https://" + REACT_APP_GQL_URL,
});

const wsLink = new WebSocketLink({
  // uri: "wss://imicon.herokuapp.com/v1/graphql",
  uri: "wss://" + REACT_APP_GQL_URL,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => {
      const accessToken = getLocalStorageAccessToken();
      if (accessToken) {
        return {
          headers: {
            authorization: getBearerToken(accessToken),
          },
        };
      } else {
        return {};
      }
    },
  },
});

const subscriptionMiddleware = {
  applyMiddleware(options, next) {
    options["authorization"] = getBearerToken(getLocalStorageAccessToken());
    next();
  },
};

wsLink.subscriptionClient.use([subscriptionMiddleware]);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "none",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "none",
  },
  mutate: {
    errorPolicy: "all",
  },
};

let links = [errorLink, authLink, splitLink];

export const client = new ApolloClient({
  link: ApolloLink.from(links),
  cache,
  defaultOptions: defaultOptions,
});

client.restartWebsocketConnection = () => {
  if (wsLink.subscriptionClient.client) {
    // Close socket connection which will also unregister subscriptions on the server-side.
    wsLink.subscriptionClient.close();

    // Reconnect to the server.
    wsLink.subscriptionClient.connect();

    /* Register all subscriptions.
       Object.keys(wsLink.operations).forEach((id) => {
       wsLink.sendMessage(id, MessageTypes.GQL_START, wsLink.operations[id].options);
      }); */
  }
};
