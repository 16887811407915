import { UserAddOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Button, message, Space, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import AddCompanyModal from "./AddCompanyModal";
import {
  INSERT_COMPANY_ONE,
  UPDATE_COMPANY_ONE,
} from "../../graphql/Mutations";
import { GET_COMPANY_DETAILS } from "../../graphql/Queries";
import EditCompany from "./EditCompany";

const { Column } = Table;

const CompanyList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const {
    loading: compDataLoading,
    data: compData,
    error: compDataError,
    refetch,
  } = useQuery(GET_COMPANY_DETAILS);

  const [registerCompany, { loading: registering, error: registerError }] =
    useMutation(INSERT_COMPANY_ONE);

  const [updateCompany, { loading: updating, error: updateError }] =
    useMutation(UPDATE_COMPANY_ONE);

  if (compDataError || registerError || updateError) {
    console.log(
      "compDataError  || registerError || updateError",
      compDataError,
      registerError,
      updateError
    );
  }

  const submitHandler = async (user) => {
    setIsOpen(false);
    try {
      const response = await registerCompany({
        variables: {
          name: user.name.trim(),
          address: user.address.trim(),
          type: user.type.trim(),
        },
      });
      if (!response.error) {
        message.success("Company added successfully");
        refetch();
      } else {
        message.error("Invalid Company data, Please contact support");
      }
    } catch (err) {
      message.error("Invalid Company data, Please contact support");
    }
  };

  const onUpdate = async (user) => {
    setEditModalOpen(false);
    try {
      const response = await updateCompany({
        variables: {
          id: companyId,
          name: user.name.trim(),
          address: user.address.trim(),
          type: user.type.trim(),
        },
      });
      if (!response.error) {
        message.success("Company updated successfully");
        refetch();
      } else {
        message.error("Invalid Company data, Please contact support");
      }
    } catch (err) {
      message.error("Invalid Company data, Please contact support");
    }
  };

  const handleEditCompany = (record) => {
    setCompanyId(record.id);
    setEditModalOpen(true);
  };

  return (
    <div className="animated fadeIn">
      <AddCompanyModal
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onSubmit={submitHandler}
        onLoading={registering}
      />

      <EditCompany
        visible={editModalOpen}
        onCancel={() => setEditModalOpen(false)}
        onSubmit={onUpdate}
        onLoading={updating}
        companyId={companyId}
      />

      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader>
              <Button
                className="register-button d-inline-flex align-items-center"
                type="primary"
                size="large"
                shape="round"
                icon={<UserAddOutlined />}
                onClick={() => setIsOpen(true)}
              >
                Add Company
              </Button>
            </CardHeader>
            <CardBody>
              {compData?.company?.length > 0 && (
                <Table
                  bordered={true}
                  loading={compDataLoading}
                  dataSource={compData?.company}
                  rowKey={(record) => record.id}
                  pagination={false}
                >
                  <Column title="Name" key="name" dataIndex="name" />
                  <Column title="Address" key="address" dataIndex="address" />
                  <Column title="Type" key="type" dataIndex="type" />
                  <Column
                    title="Created Date"
                    key="created_date"
                    render={(record) =>
                      moment(record.created_at).format("Do MMMM YYYY")
                    }
                  />
                  <Column
                    title="Updated Date"
                    key="updated_date"
                    render={(record) =>
                      moment(record.created_at).format("Do MMMM YYYY")
                    }
                  />
                  <Column
                    title="Action"
                    key="action"
                    render={(record) => (
                      <Space>
                        <Button
                          shape="round"
                          type="primary"
                          onClick={() => handleEditCompany(record)}
                        >
                          Edit
                        </Button>
                        <Space />
                        <Button
                          shape="round"
                          type="primary"
                          danger
                          ghost
                          onClick={() => {}}
                        >
                          Delete
                        </Button>
                      </Space>
                    )}
                  />
                </Table>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyList;
