
export const TIMEZONE = "Asia/Kolkata";
export const YEAR_MONTH_DATE_FORMAT_WITH_DASHES = "YYYY-MM-DD";

export const WEEK_DAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];