import React from "react";
import { Button, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";

function SendEmailModalMessage({ visible, onCancel, onSubmit, onLoading }) {
  return (
    <div>
      <Modal
        title="Verify/Edit Email Content Before Sending"
        open={visible}
        onCancel={onCancel}
        footer={false}
      >
        <div>
          <TextArea
            rows={25}
            cols={4}
            value={
              "Hi, \n\n" +
              "Dr. Shabbir S. Gadi is not available on 12th June 2020.\n\n Please contact doctor for further details. \n\n" +
              "Thanks, \n" +
              "RajClinic."
            }
          ></TextArea>
          <Button type="primary" style={{ marginTop: 10 }} onClick={onSubmit}>
            Send Email Now
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default SendEmailModalMessage;
