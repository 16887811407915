import React from "react";
import { Navigate } from "react-router-dom";
import AppLayout from "../pages/Layout/AppLayout";
import { getLocalStorageAccessToken } from "./common-utils";

export const PrivateRoute = ({ children }) => {
  const activeUser = !!getLocalStorageAccessToken();
  return activeUser ? (
    <AppLayout>{children}</AppLayout>
  ) : (
    <Navigate to="/login" />
  );
};
