import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, message, Space, Table } from "antd";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { GET_CONFIG } from "../../graphql/Queries";
import { UserAddOutlined } from "@ant-design/icons";
import { INSERT_CONFIG_ONE, UPDATE_CONFIG_ONE } from "../../graphql/Mutations";
import AddConfig from "./AddConfig";
import EditConfig from "./EditConfig";

const { Column } = Table;

const Configuration = () => {
  const [isOpenModal, setIsModalOpen] = useState(false);
  const [configId, setConfigId] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);

  const { loading: dataLoading, data, refetch } = useQuery(GET_CONFIG);

  const [updateConfig, { loading: updating, error: updateError }] =
    useMutation(UPDATE_CONFIG_ONE);

  const [addConfig, { loading: adding, error }] =
    useMutation(INSERT_CONFIG_ONE);

  const submitHandler = async (user) => {
    setIsModalOpen(false);
    try {
      const response = await addConfig({
        variables: {
          name: user.name.trim(),
          value: user.value.trim(),
        },
      });
      if (!response.error) {
        message.success("Config added successfully");
        refetch();
      } else {
        message.error("Invalid Config data, Please contact support");
      }
    } catch (err) {
      message.error("Invalid Config data, Please contact support");
    }
  };

  if (error || updateError) {
    console.log("updateError  | error", updateError, error);
  }

  const onUpdate = async (config) => {
    setEditModalOpen(false);
    try {
      const response = await updateConfig({
        variables: {
          id: configId,
          name: config.name.trim(),
          value: config.value,
        },
      });
      if (!response.error) {
        message.success("Configuration updated successfully");
        refetch();
      } else {
        message.error("Invalid Configuration data, Please contact support");
      }
    } catch (err) {
      message.error("Invalid Configuration data, Please contact support");
    }
    setConfigId("");
  };

  const handleEdit = (record) => {
    setConfigId(record.id);
    setEditModalOpen(true);
  };

  return (
    <>
      <AddConfig
        visible={isOpenModal}
        onCancel={() => setIsModalOpen(false)}
        onSubmit={submitHandler}
        onLoading={adding}
      />

      <EditConfig
        visible={editModalOpen}
        onCancel={() => setEditModalOpen(false)}
        onSubmit={onUpdate}
        onLoading={updating}
        configId={configId}
      />

      <div className="animated fadeIn">
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <Button
                  className="register-button d-inline-flex align-items-center"
                  type="primary"
                  size="large"
                  shape="round"
                  icon={<UserAddOutlined />}
                  onClick={() => setIsModalOpen(true)}
                >
                  Add Configuration
                </Button>
              </CardHeader>
              <CardBody>
                <Table
                  bordered={true}
                  loading={dataLoading}
                  dataSource={data?.configuration}
                  rowKey={(record) => record?.id}
                  pagination={false}
                >
                  <Column title="Name" key="name" dataIndex="name" />
                  <Column title="Value" key="value" dataIndex="value" />
                  <Column
                    title="Action"
                    key="action"
                    render={(record) => (
                      <Space>
                        <Button
                          shape="round"
                          type="primary"
                          onClick={() => {
                            handleEdit(record);
                          }}
                        >
                          Edit
                        </Button>
                        <Space />
                        <Button
                          shape="round"
                          type="primary"
                          danger
                          ghost
                          onClick={() => {}}
                        >
                          Delete
                        </Button>
                      </Space>
                    )}
                  />
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Configuration;
