import { useMutation, useQuery } from "@apollo/client";
import { Modal, Button, Card, Descriptions, Input, message } from "antd";
import React, { useState } from "react";
import Loader from "../../components/Loader/Loader";
import { CHANGE_PASSWORD } from "../../graphql/Mutations";
import { GET_USER_DETAILS_BY_ID } from "../../graphql/Queries";
const Profile = () => {
  const [isOpenModal, setIsModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const user_id = localStorage.getItem("user_id");

  const [changePassword, { loading: changingPassword, error: passwordError }] =
    useMutation(CHANGE_PASSWORD);

  const { data: userData, loading: userDataLoading } = useQuery(
    GET_USER_DETAILS_BY_ID,
    {
      variables: {
        id: user_id,
      },
    }
  );

  if (passwordError) {
    console.log("passwordError", passwordError);
  }

  const handleChangePassword = async () => {
    setIsModalOpen(false);
    try {
      const response = await changePassword({
        variables: {
          new_password: newPassword.trim(),
        },
      });

      if (!response.error) {
        message.success(`${response.data.change_password.message}`);
        setNewPassword("");
      } else {
        message.error("Something went wrong, Please contact support");
      }
    } catch (error) {
      message.error("Something went wrong, Please contact support");
    }
  };

  return (
    <div className="container">
      <Modal
        className=""
        title="New password"
        open={isOpenModal}
        onCancel={() => setIsModalOpen(false)}
        onOk={handleChangePassword}
      >
        <Input
          value={newPassword}
          placeholder="Enter new password"
          type="password"
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </Modal>
      <Card>
        <div className="d-flex border-bottom mb-3 pb-2">
          <h4>User Profile</h4>
          <Button
            className="ms-auto"
            shape="round"
            type="primary"
            htmlType="button"
            loading={changingPassword}
            onClick={() => setIsModalOpen(true)}
          >
            Change password
          </Button>
        </div>
        {userDataLoading ? (
          <Loader />
        ) : (
          userData && (
            <div>
              <Descriptions bordered>
                <Descriptions.Item label="Name">
                  {userData?.user_by_pk?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Email" span={2}>
                  {userData?.user_by_pk?.email}
                </Descriptions.Item>
                <Descriptions.Item label="Phone">
                  {userData?.user_by_pk?.phone}
                </Descriptions.Item>
                <Descriptions.Item label="Company" span={2}>
                  {userData?.user_by_pk?.company?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Department">
                  {userData?.user_by_pk?.departmentByDepartmentId?.name}
                </Descriptions.Item>
              </Descriptions>
            </div>
          )
        )}
      </Card>
    </div>
  );
};

export default Profile;
